import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import OmegaCategoryBtns from '../../components/preowned/OmegaCategoryBtns'

// markup
const OmegaConstellation = () => {
  const data = useStaticQuery(
    graphql`
      query queryOmegaConstellation {
        products: allStrapiProduct(
          filter: { brand: { eq: "Omega" }, model: { eq: "Constellation" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Omega Constellation Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/omega/omega-constellation/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Omega Constellation Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/omega/omega-constellation/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-omega-constellation-header.png"
                alt="Pre-Owned Certified Used Omega Constellation Watches Header"
                aria-label="Used Omega Constellation Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Omega Constellation</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE OMEGA CONSTELLATION WATCHES
              AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED OMEGA CONSTELLATION WATCHES
              WITH GRAY AND SONS JEWELERS
            </h3>
            <p>
              <br />
              <br />
              There are no better certified pre-owned Omega watch specialists than Gray and Sons
              Jewelers in all of South Florida. We deal with all Omega watch needs including buying
              Omega watches, selling used Omega watches, repairing Omega watches, and trading
              pre-owned Omega watches. Our physical walk-in store is located in Bal Harbour in the
              heart of Miami, Florida. We’re the best and nearest watch specialists to cities like
              Sunny Isles, Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut
              Grove, Coral Gables, Key Biscayne, and many more. Clients outside of Florida can
              contact us via <a href="/">www.grayandsons.com </a>
              and speak with live representatives, Rich and Viktoria, about all Omega Constellation
              watch inquiries today. Gray and Sons Jewelers is the best and most reliable place to
              buy used pre-owned Omega Constellation watches. Our master-trained watchmakers assure
              every Omega watch in our inventory is certified authentic and serviced ready for wear
              with a 1-year warranty covering the movement. We offer certified authentic and
              certfied pre-owned watches.
              <br />
              <br />
              <b>About Pre-Owned Omega Seamaster Watches at Gray and Sons</b>
              <br />
              <br />
              Being one of Omega’s longest-standing watch models still in fabrication, the Omega
              Constellation collection is a historic watch model admired by collectors around the
              world. As Omega’s first wearable chronometer timepiece, the Omega Constellation has
              had various looks and transitions over the years. In 1969, the Omega Constellation was
              one of the first watches in the world to have the bracelet cohesively be incorporated
              into the case of the watch.
              <br />
              <br />
              In the 1970s, Omega took advantage of the worldwide quartz crisis and released three
              battery-operated Omega Constellation models which performed amazingly with the watch
              market. By the 1990s, the Omega Constellation had taken a more crisp and refined look
              by obtaining a more defined bezel and some models offering a chronograph complication.
              In the 2000s, Omega incorporated its iconic Co-Axial escapement movements into its
              Constellation models.
              <br />
              <br />
              However, the next ground-breaking innovation Omega added to its Constellation models
              happened a decade and a half later when Omega incorporated the Master Chronometer
              certification which ensured water resistance and resistance to magnetic fields
              altering the movement parts. Omega watches come in precious metals such as 14k and 18k
              yellow and rose gold and stainless steel. His and Hers models are made in all sizes
              for the Constellation from 25mm to 41mm. Since the first release, the Omega
              Constellation has been a complete success loved by clients around the world.
              <br />
              <br />
              Popular Pre-Owned Omega Constellation References are: Reference 1528.76.00 Reference
              1521.71.00 Reference 168029 Reference 59469775 Reference 123.20.35.20.02.004 Reference
              131.12.41.21.06.001 Reference 168017 Reference 1212.30.00 Reference 796.2600 Reference
              123.20.38.22.02.002 Reference 1562.30.00 Reference 396.1070 Reference
              123.53.38.21.02.001 Reference 196.005
              <br />
              <br />
              Watch collectors often visit Gray and Sons Jewelers to find the most collectible Omega
              Constellation watches. Vintage Omega Constellation is some of the most desirable
              vintage collection watches in the market. Vintage Omega watches with 24 jewel
              movements caliber 551 and caliber 561 from 1952 and 1966 are often seen around Gray
              and Sons. We buy, sell, trade, and repair collectible Omega Constellation models.
              Visit our website to read all about our Vintage Omega Constellation reference
              14381-61SC with no date or our Vintage Omega Constellation reference 168.005 with the
              date.
              <br />
              <br />
              <b>Buy Pre-Owned Omega Seamaster Watches</b>
              <br />
              <br />
              Some people think buying directly from the Omega boutique is better than getting a
              pre-owned authentic Omega Constellation however this is not true. Buying a certified
              genuine used Omega comes with several perks, such as saving money, getting a hold of
              exclusive or discontinued models, or even finding out-of-stock watches. Gray and Sons
              Jewelers has vintage Omega watches and modern Omegas watches in stock available for
              purchase.
              <br />
              <br />
              Nationally, Omega watches are recognized for being one of the top watch brands in the
              luxury market and are constantly in demand. If looking to buy an Omega watch or sell
              an Omega watch, you should only trust your timepiece in the hands of the best. Here at
              Gray and Sons Jewelers, we have six Master-trained watchmakers with over 150 years of
              combined experience who fully service every single Omega watch in our inventory.
              <br />
              <br />
              <b>Sell Used Omega Seamaster Watches</b>
              <br />
              <br />
              Every day, clients call asking “Where can I sell my pre-owned watch near me?” or “What
              is the most I can get for my used watch?”. Gray and Sons Jewelers has a buying
              department called Sell Us Your Jewelry where clients can sell their watches and
              jewelry to us without ever coming into our store! Contact our expert Omega watch
              specialist at <a href="/chat-with-rich">CHAT WITH RICH </a>
              Sell Us Your Jewelry at{' '}
              <a href="https://www.sellusyourjewelry.com">www.sellusyourjewelry.com</a> offers the
              best prices for pre-owned watches to clients all around the nation! We buy Omega
              watches from clients all over, even in states like New York, Los Angeles, Nevada,
              Illinois, Texas, and many more!
              <br />
              <br />
              <b>Service Pre-Owned Omega Seamaster Watches</b>
              <br />
              <br />
              We provide repair services to the public as well as provide a 1-year warranty for all
              Omega Constellation watches sold at Gray and Sons. In-house we offer the following
              services: cleaning, polishing, overhaul and lubrication of the movement, battery
              changing, waterproofing, and much more! Come visit our store to repair my Omega
              Constellation watch.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/omega/omega-constellation/">
                <button>SHOP OMEGA WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK OMEGA WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <OmegaCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default OmegaConstellation
